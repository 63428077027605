#header:after,
.search-form:after,
.container:after,
.products:after,
.products .heading:after,
#footer .holder:after,
#footer .payments ul:after,
#footer .btns:after,
.team-section .team-list:after,
.product-block:after,
.product-block .tumbnails:after {
	content: " ";
	display: block;
	clear: both;
}

#nav,
#nav > li,
.products ul,
.products ul li,
#footer .payments ul,
#footer .payments ul > li,
.links,
.links li,
.team-section .team-list,
.team-section .team-list > li,
.filters,
.filters li,
.filters ul,
.products-list,
.products-list > li,
.paging ul,
.paging ul li,
.product-block .description ul,
.product-block .description ul li,
.product-block .tumbnails,
.product-block .tumbnails li,
.product-block .product-image .image ul,
.product-block .product-image .image ul li {
	margin: 0;
	padding: 0;
	list-style: none;
}

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

* {
	margin: 0;
	padding: 0;
	max-height: 1000000px;
}

ul,
ol,
dl,
p,
h1,
h2,
h3,
h4,
h5,
h6,
address,
form,
table,
blockquote,
applet,
embed,
object,
iframe,
frameset {
	margin: 0 0 27px;
}

html,
body,
div,
span,
applet,
embed,
object,
iframe,
frameset,
p,
q,
blockquote,
sub,
sup,
a,
abbr,
acronym,
font,
img,
small,
center,
h1,
h2,
h3,
h4,
h5,
h6,
b,
strong,
big,
u,
ins,
i,
cite,
em,
var,
dfn,
address,
s,
strike,
del,
dl,
dt,
dd,
ol,
ul,
li,
pre,
code,
kbd,
samp,
tt,
fieldset,
legend,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
	border: 0;
	background: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
	display: block;
}

body {
	color: #292929;
	font: 18px/1.5 "Avenir", "Helvetica", sans-serif;
	background: #fff;
	overflow-y: scroll;
	min-width: 320px;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: none;
	font-weight: 500;
}

a {
	text-decoration: underline;
	color: #171e6d;
	outline: none;
}

a:hover {
	text-decoration: none;
}

img {
	max-width: 100%;
	height: auto;
}

video,
iframe,
object,
embed {
	max-width: 100%;
}

input,
textarea,
select {
	font: 100% "Avenir", "Helvetica", sans-serif;
	color: #292929;
	vertical-align: middle;
}

textarea {
	overflow: auto;
	resize: vertical;
	vertical-align: top;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
textarea {
	background: #fff;
	margin: 0;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	border: 1px solid #999;
	padding: 3px 7px;
}

input[type="text"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="url"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="search"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #999;
}

input[type="text"]::-moz-placeholder,
input[type="tel"]::-moz-placeholder,
input[type="email"]::-moz-placeholder,
input[type="url"]::-moz-placeholder,
input[type="password"]::-moz-placeholder,
input[type="search"]::-moz-placeholder,
textarea::-moz-placeholder {
	opacity: 1;
	color: #999;
}

input[type="text"]:-moz-placeholder,
input[type="tel"]:-moz-placeholder,
input[type="email"]:-moz-placeholder,
input[type="url"]:-moz-placeholder,
input[type="password"]:-moz-placeholder,
input[type="search"]:-moz-placeholder,
textarea:-moz-placeholder {
	color: #999;
}

input[type="text"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: #999;
}

input[type="text"].placeholder,
input[type="tel"].placeholder,
input[type="email"].placeholder,
input[type="url"].placeholder,
input[type="password"].placeholder,
input[type="search"].placeholder,
textarea.placeholder {
	color: #999;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {
	border-color: #4d4d4d;
}

label {
	vertical-align: middle;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	padding: 0;
	border: 0;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}

input[type="search"] {
	-webkit-appearance: none;
	box-sizing: border-box;
}

input[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
	display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong,
th {
	font-weight: bold;
}

h1 {
	font-size: 200%;
}

h2 {
	font-size: 150%;
}

h3 {
	font-size: 120%;
}

h4 {
	font-size: 110%;
}

h5 {
	font-size: 100%;
}

h6 {
	font-size: 90%;
}

i,
cite,
em,
var,
dfn,
address {
	font-style: italic;
}

u,
ins {
	text-decoration: underline;
}

s,
strike,
del {
	text-decoration: line-through;
}

blockquote,
blockquote:before,
blockquote:after,
q,
q:before,
q:after {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
}

blockquote {
	border-left: 4px solid #ccc;
	padding-left: 10px;
	margin-left: 27px;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -.5em;
}

sub {
	bottom: -.25em;
}

table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
	border-collapse: collapse;
	border-spacing: 0;
}

th,
td {
	border: 1px solid #ccc;
	padding: 3px 7px;
	text-align: left;
	vertical-align: top;
}

th {
	border-color: #999;
	text-align: center;
	vertical-align: middle;
}

ul {
	margin-left: 1px;
}

ul,
ol {
	padding-left: 3em;
}

ul ul,
ul ol,
ol ul,
ol ol {
	padding-left: 3em;
	margin: 0;
}

ul > li {
	list-style-type: disc;
}

dl dt {
	float: left;
	clear: left;
	padding-right: 4px;
	font-weight: bold;
}

pre {
	max-width: 100%;
	overflow: auto;
}

@font-face {
	font-family: "Avenir";
	src: url("avenir-black-webfont.eot");
	src: url("avenir-black-webfont.eot?#iefix") format("embedded-opentype"), url("avenir-black-webfont.woff2") format("woff2"), url("avenir-black-webfont.woff") format("woff"), url("avenir-black-webfont.ttf") format("truetype"), url("avenir-black-webfont.svg#avenirregular") format("svg");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Avenir";
	src: url("avenir-mediumoblique-webfont.eot");
	src: url("avenir-mediumoblique-webfont.eot?#iefix") format("embedded-opentype"), url("avenir-mediumoblique-webfont.woff2") format("woff2"), url("avenir-mediumoblique-webfont.woff") format("woff"), url("avenir-mediumoblique-webfont.ttf") format("truetype"), url("avenir-mediumoblique-webfont.svg#avenirmediumoblique_75173") format("svg");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Avenir";
	src: url("avenir-blackoblique-webfont.eot");
	src: url("avenir-blackoblique-webfont.eot?#iefix") format("embedded-opentype"), url("avenir-blackoblique-webfont.woff2") format("woff2"), url("avenir-blackoblique-webfont.woff") format("woff"), url("avenir-blackoblique-webfont.ttf") format("truetype"), url("avenir-blackoblique-webfont.svg#aveniroblique_77173") format("svg");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Avenir";
	src: url("avenir-medium-webfont.eot");
	src: url("avenir-medium-webfont.eot?#iefix") format("embedded-opentype"), url("avenir-medium-webfont.woff2") format("woff2"), url("avenir-medium-webfont.woff") format("woff"), url("avenir-medium-webfont.ttf") format("truetype"), url("avenir-medium-webfont.svg#avenirmedium_06173") format("svg");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "icomoon";
	src: url("icomoon.eot?-imz7yg");
	src: url("icomoon.eot?#iefix-imz7yg") format("embedded-opentype"), url("icomoon.ttf?-imz7yg") format("truetype"), url("icomoon.woff?-imz7yg") format("woff"), url("icomoon.svg?-imz7yg#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
	font-family: "icomoon";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1; /* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-tweet:before {
	content: "\e602";
}

.icon-search:before {
	content: "\e600";
}

.icon-cart:before {
	content: "\e601";
}

#wrapper {
	overflow: hidden;
	position: relative;
}

#header {
	position: relative;
	max-width: 970px;
	margin: 0 auto;
	padding: 15px 15px 15px;
}

#header .logo {
	float: left;
	margin: 0 22px 0 0;
}

#header .logo img {
	vertical-align: top;
}

#header .cart-link {
	margin: 14px 1px 0 0;
	float: right;
	font-weight: bold;
	color: #171e6d;
	text-decoration: none;
	font-size: 18px;
	line-height: 22px;
	background: #f9d41f;
	min-width: 59px;
	height: 59px;
	padding: 20px 0 0 14px;
}

#header .cart-link:hover {
	opacity: .8;
}

#header .cart-link i {
	font-size: 20px;
	vertical-align: top;
	margin-right: 3px;
	color: #221f1f;
}

#nav {
	float: left;
	font-size: 18px;
	line-height: 20px;
	text-transform: uppercase;
	font-weight: bold;
	margin: 37px 0 0;
}

#nav > li {
	float: left;
	margin: 0 40px 0 0;
	position: relative;
}

#nav > li:before {
	position: absolute;
	left: -24px;
	top: 8px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: #f9d41f;
	content: "";
}

#nav > li:first-child:before {
	display: none;
}

#nav > li > a {
	text-decoration: none;
	color: #292929;
}

#nav > li:hover:after,
#nav > li.active:after {
	position: absolute;
	left: 0;
	bottom: -7px;
	height: 3px;
	overflow: hidden;
	text-indent: -9999px;
	content: "";
	width: 100%;
	background: #f9d41f;
}

.image-box {
	margin: 0;
}

.image-box img {
	max-width: 100%;
	height: auto;
	display: block;
	margin: 0 auto;
}

.search-section {
	background: #151c67;
	font-size: 24px;
	line-height: 29px;
	color: #fff;
	text-shadow: 1px 1px 1px #000;
	font-weight: bold;
}

.search-section.alt {
	background: #e7e8f0;
	margin: 0 0 87px;
}

.search-section.alt h1 {
	font-size: 36px;
	line-height: 40px;
	color: #292929;
	text-transform: none;
	text-shadow: none;
	margin: 0 0 24px;
}

.search-section.alt .holder {
	padding: 73px 15px 80px;
}

.search-section.alt .search-form {
	box-shadow: none;
	max-width: 537px;
}

.search-section .holder {
	max-width: 970px;
	margin: 0 auto;
	padding: 50px 15px 50px;
	text-align: center;
}

.search-section h1 {
	font-size: 60px;
	line-height: 64px;
	color: #f9d41f;
	margin: 0 0 1px;
	text-transform: uppercase;
}

.search-section p {
	margin: 0 0 22px;
}

.search-form {
	background: #6166a3;
	max-width: 448px;
	margin: 0 auto;
	padding: 17px 18px 16px 20px;
	box-shadow: 0 2px 0 rgba(0, 0, 0, .2);
}

.search-form .submit {
	float: right;
	background: #131b63;
	width: 50px;
	height: 50px;
	position: relative;
	margin: -50px 0 0 12px;
}

.search-form .submit i {
	position: absolute;
	left: 0;
	width: 100%;
	text-align: center;
	top: 0;
	color: #fff;
	font-size: 30px;
	line-height: 50px;
}

.search-form .submit input {
	background: none;
	border: 0;
	outline: none;
	cursor: pointer;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-indent: -9999px;
	border-radius: 0;
}

.search-form .area {
	#overflow: hidden;
}

.search-form .area input {
	width: 100%;
	background: #fff;
	border: 0;
	height: 50px;
	padding: 5px 15px;
	color: #6c6e93;
	font-size: 18px;
	line-height: 20px;
	margin: 0;
	display: block;
	border-radius: 0;
}

.search-form .area input::-webkit-input-placeholder {
	color: #6c6e93;
}

.search-form .area input::-moz-placeholder {
	opacity: 1;
	color: #6c6e93;
}

.search-form .area input:-moz-placeholder {
	color: #6c6e93;
}

.search-form .area input:-ms-input-placeholder {
	color: #6c6e93;
}

.search-form .area input.placeholder {
	color: #6c6e93;
}

.container {
	max-width: 1000px;
	margin: 0 auto;
	padding: 0 15px 20px;
}

.container .aside {
	float: right;
	width: 220px;
	margin: 0 0 0 31px;
}

.container .content {
	overflow: hidden;
}

.products {
	overflow: hidden;
	padding: 30px 0 7px;
}

.products .products-holder {
	margin: 0 -15px;
	font-size: 0;
	line-height: 0;
	letter-spacing: -4px;
}

.products .col {
	display: inline-block;
	vertical-align: top;
	width: 33.33%;
	padding: 0 15px 15px;
	letter-spacing: 0;
	font-size: 18px;
	line-height: 24px;
	font-weight: bold;
}

.products .col > a:hover {
	opacity: .8;
}

.products .col h2,
.products .col h1 {
	background: #171e6d;
	font-size: 22px;
	line-height: 26px;
	color: #f9d41f;
	margin: 0 0 30px;
	text-transform: uppercase;
	padding: 23px 18px 19px;
}

.products .image {
	display: block;
	background: #e7e8f0;
	text-align: center;
}

.products .image img {
	vertical-align: top;
	max-width: 100%;
	height: auto;
}

.products ul {
	line-height: 19px;
}

.products ul a {
	text-decoration: none;
	color: #292929;
	display: block;
	padding: 3px 5px 3px 20px;
}

.products ul a:hover {
	background: #e7e8f0;
}

.products .heading {
	background: #f9d41f;
	padding: 22px 21px 19px;
	margin: 0 0 30px;
	border-bottom: 1px solid #e9c71e;
}

.products .heading h1 {
	float: left;
	font-size: 24px;
	line-height: 26px;
	color: #292929;
	margin: 0;
	text-transform: uppercase;
}

.products .heading .link {
	float: right;
	color: #292929;
	font-size: 18px;
	line-height: 20px;
	text-decoration: none;
	margin: 4px 5px 0 0;
	font-weight: bold;
}

.products .heading .link:hover {
	text-decoration: underline;
}

.faq-box {
	background: #e7e8f0;
	margin: 9px 0 84px;
}

.faq-box .holder {
	max-width: 970px;
	margin: 0 auto;
	padding: 65px 15px 69px;
	text-align: center;
	font-weight: bold;
}

.faq-box .title {
	display: block;
	font-size: 30px;
	line-height: 32px;
	margin: 0 0 18px;
}

.faq-box .btn {
	display: inline-block;
	vertical-align: top;
	font-size: 24px;
	line-height: 26px;
	text-decoration: none;
	color: #fff;
	padding: 22px 54px 21px;
	background: #c8302c;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
}

.faq-box .btn:hover {
	background: #000;
}

.add-box {
	max-width: 970px;
	margin: 0 auto 90px;
	padding: 0 15px;
	text-align: center;
}

.add-box img {
	margin: 0 auto;
	max-width: 100%;
	height: auto;
	vertical-align: top;
}

#footer {
	background: #171e6d;
	color: #fff;
	color: #6c6f94;
	font-size: 14px;
	line-height: 24px;
}

#footer p {
	margin: 0 0 10px;
}

#footer .holder {
	max-width: 970px;
	margin: 0 auto;
	padding: 82px 0 60px;
}

#footer .col {
	float: left;
	width: 50%;
	padding: 0 15px;
}

#footer .textarea {
	padding: 3px 0 0;
}

#footer .textarea p {
	margin: 0 0 24px;
}

#footer .payments {
	margin: 0 0 12px;
}

#footer .payments ul > li {
	float: left;
	margin: 0 3px 3px 0;
}

#footer .btns {
	margin: 0 -15px 33px;
	font-weight: bold;
	font-size: 18px;
	line-height: 27px;
}

#footer .btns span {
	padding: 0 15px;
	float: left;
	width: 50%;
}

#footer .btns a {
	text-decoration: none;
	background: #6166a3;
	font-weight: bold;
	height: 52px;
	display: block;
	padding: 13px 18px;
}

#footer .btns a:hover {
	opacity: .8;
}

#footer .btns i {
	float: right;
	margin: -5px -8px 0 0;
	font-size: 34px;
	line-height: 40px;
	vertical-align: top;
}

#footer .btns .icon-stars {
	background: url(stars.png) no-repeat;
	width: 33px;
	height: 28px;
	overflow: hidden;
	text-indent: -9999px;
	margin-top: -2px;
}

#footer .copy {
	color: #fff;
	font-weight: bold;
}

.links {
	font-weight: bold;
	color: #f9d41f;
	font-size: 18px;
	line-height: 20px;
	margin: 0 0 34px;
}

.links span {
	color: #6166a3;
}

.links li {
	margin: 0 0 4px;
}

.links li.active a {
	color: #fff;
}

.links a {
	text-decoration: none;
	color: #f9d41f;
}

.links a:hover {
	color: #fff;
}

.nav-opener {
	width: 59px;
	height: 59px;
	background: #000;
	background: rgba(0, 0, 0, .7);
	position: relative;
	border-radius: 3px;
	text-indent: -9999px;
	overflow: hidden;
	display: none;
	margin: 14px 0 0 10px;
	outline: none;
	float: right;
}

.nav-opener:before,
.nav-opener:after,
.nav-opener span {
	background: #fff;
	border-radius: 4px;
	position: absolute;
	top: 29px;
	left: 20%;
	right: 20%;
	height: 5px;
	margin-top: -2px;
	-webkit-transition: all .2s linear;
	transition: all .2s linear;
}

.nav-opener:before,
.nav-opener:after {
	content: "";
	top: 18px;
}

.nav-opener:after {
	top: 41px;
}

.nav-active .nav-opener span {
	opacity: 0;
}

.nav-opener:hover {
	opacity: .9;
}

.nav-active .nav-opener:after,
.nav-active .nav-opener:before {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	top: 28px;
	left: 15%;
	right: 15%;
}

.nav-active .nav-opener:after {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.nav-opener:before,
.nav-opener:after,
.nav-opener span {
	background: #fff;
}

.post-section {
	margin: -3px 0 0;
	padding: 0 0 45px;
}

.post-section h1 {
	margin: 0 0 12px;
	font-size: 24px;
	line-height: 26px;
	text-transform: uppercase;
}

.post-section p {
	margin: 0 0 20px;
}

.team-section h1 {
	color: #292929;
	font-size: 24px;
	line-height: 26px;
	margin: 0 0 30px;
	background: #f9d41f;
	padding: 22px 22px 19px;
	text-transform: uppercase;
	border-bottom: 1px solid #e9c71e;
}

.team-section .team-list {
	margin: 0 -15px 53px;
	font-size: 0;
	line-height: 0;
	letter-spacing: -4px;
}

.team-section .team-list > li {
	width: 33.33%;
	padding: 0 15px 15px;
	letter-spacing: 0;
	display: inline-block;
	vertical-align: top;
}

.team-section .team-list .image {
	display: block;
	background: #e7e8f0;
}

.team-section .team-list .image img {
	width: 100%;
	height: auto;
	vertical-align: top;
}

.team-section .team-list .name {
	display: block;
	font-size: 24px;
	line-height: 26px;
	color: #f9d41f;
	padding: 22px 13px 20px;
	background: #171e6d;
}

.team-section .team-list .name span {
	color: #6166a3;
}

.content .heading {
	margin: 0 0 18px;
	padding: 13px 22px 12px;
	text-transform: uppercase;
	background: #f9d41f;
}

.content .heading h1 {
	font-size: 24px;
	line-height: 28px;
	margin: 0;
}

.filters {
	font-weight: bold;
}

.filters .opener {
	display: block;
	background: #f9d41f;
	text-transform: uppercase;
	font-size: 18px;
	line-height: 20px;
	color: #292929;
	padding: 17px 15px 16px;
	text-decoration: none;
	font-weight: bold;
	margin: 0 0 17px;
}

.filters li {
	margin: 0 0 -1px;
}

.filters .slide {
	overflow: hidden;
	width: 100%;
}

.filters ul {
	margin: 0 0 30px;
}

.filters ul > li.active > a {
	background: #e7e8f0;
}

.filters ul a {
	text-decoration: none;
	display: block;
	color: #292929;
	padding: 3px 25px;
}

.filters ul a:hover {
	background: #e7e8f0;
}

.products-list {
	margin: 0 -15px 17px;
	font-size: 0;
	line-height: 0;
	letter-spacing: -4px;
}

.products-list a:hover {
  text-decoration: underline;
  
}

.products-list > li {
	display: inline-block;
	vertical-align: top;
	width: 33.33%;
	padding: 0 15px 28px;
	letter-spacing: 0;
	font-size: 18px;
	line-height: 20px;
}

.products-list .price {
	text-align: right;
	font-weight: bold;
	color: #fff;
	padding: 10px 0 1px;
	display: block;
	border-top: 2px solid #404588;
}

.products-list .image {
	display: block;
	background: #e7e8f0;
}

.products-list .image img {
	width: 100%;
	height: auto;
	vertical-align: top;
}

.products-list h2 {
	color: #f9d41f;
	font-size: 18px;
	line-height: 18px;
	margin: 0 0 14px;
}

.products-list h2 span {
	color: #6166a3;
	font-size: 14px;
}

.products-list h2 a {
	color: #f9d41f;
	text-decoration: none;
}

.products-list .entry {
	padding: 14px 15px 7px;
	background: #171e6d;
}

.paging {
	text-align: center;
	margin: 0 0 70px;
}

.paging .pages {
	display: block;
	font-size: 14px;
	line-height: 16px;
	margin: 0 0 29px;
	font-weight: bold;
}

.paging ul {
	display: inline-block;
	vertical-align: top;
	border: 1px solid #ddd;
	border-left: 0;
	font-size: 15px;
	line-height: 34px;
}

.paging ul li {
	float: left;
	border-left: 1px solid #ddd;
	height: 32px;
	min-width: 32px;
}

.paging ul li.active {
	background: #f8d31f;
}

.paging ul a {
	display: block;
	text-decoration: none;
	text-align: center;
	height: 100%;
	color: #3a3a3a;
}

.paging ul a:hover {
	background: #f8d31f;
}

.product-block {
	margin: 0 0 35px;
}

.product-block .heading {
	background: #171e6d;
	padding: 23px 20px 22px;
	margin: 1px 0 27px;
}

.product-block .heading h1 {
	font-size: 24px;
	line-height: 28px;
	color: #f9d41f;
	margin: 0;
	text-transform: uppercase;
}

.product-block .heading h1 span {
	color: #6166a3;
}

.product-block .col {
	float: right;
	width: 293px;
	margin: 5px 0 0 30px;
}

.product-block .area {
	overflow: hidden;
}

.product-block .description {
	padding: 0 0 5px 20px;
}

.product-block .description p {
	margin: 0 0 45px;
}

.product-block .description ul {
	margin: 4px 0 25px 38px;
	line-height: 36px;
}

.product-block .description ul li {
	position: relative;
	padding: 0 0 0 33px;
}

.product-block .description ul li:before {
	position: absolute;
	left: 0;
	top: 14px;
	width: 6px;
	height: 6px;
	background: #292929;
	content: "";
	border-radius: 50%;
}

.product-block .tumbnails {
	margin: 0 -30px 10px 0;
}

.product-block .tumbnails li {
	float: left;
	margin: 0 30px 10px 0;
}

.product-block .tumbnails img {
	vertical-align: top;
}

.product-block .product-image .image {
	background: #e7e8f0;
	margin: 0 0 15px;
}

.product-block .product-image .image img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
	display: block;
	margin: 0 auto;
}

.product-block .product-image .image ul {
	position: relative;
}

.product-block .product-image .image ul li {
	left: 0;
	top: 0;
	width: 100%;
	position: absolute;
}

.product-block .product-image .image ul li.active {
	z-index: 2;
}

.product-block .select {
	border: solid #e7e8f0;
	border-width: 3px 0;
	padding: 20px 0 25px;
	margin: 0 0 21px;
}

.product-block .btn {
	background: #9e1f1a;
	font-size: 18px;
	line-height: 20px;
	color: #fff;
	display: block;
	border: 0;
	padding: 24px 20px;
	width: 100%;
	font-weight: bold;
}

.product-block .btn:hover {
	background: #000;
}

@media only screen and (min-width: 981px) {
	.filters .slide {
		position: static !important;
		display: block !important;
		left: 0 !important;
		top: 0 !important;
		height: auto !important;
	}
}

@media only screen and (max-width: 980px) {
	#nav > li {
		margin: 0 16px 0 0;
	}

	#nav > li:before {
		left: -11px;
	}

	.search-section h1 {
		font-size: 47px;
	}

	.team-section .team-list .name,
	.products .col h2,
	.products .col h1 {
		font-size: 19px;
	}

	.container .aside {
		float: none;
		margin: 0;
		width: auto;
	}

	.filters .opener {
		cursor: pointer;
		position: relative;
	}

	.filters .opener:before {
		position: absolute;
		right: 15px;
		top: 50%;
		margin: -5px 0 0;
		content: "";
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 10px 7px 0 7px;
		border-color: #000 transparent transparent;
	}

	.content .heading h1 {
		text-align: center;
	}

	.product-block .col {
		width: auto;
		margin: 0 0 20px;
		float: none;
	}

	.product-block .description {
		padding-left: 0;
	}
}

@media only screen and (max-width: 767px) {
	#header {
		padding: 20px 15px;
	}

	.nav-opener {
		display: block;
	}

	.nav-box {
		position: absolute;
		left: 0;
		top: 100%;
		overflow: hidden;
		width: 100%;
		z-index: 100;
		max-height: 0;
		-webkit-transition: all .25s linear;
		transition: all .25s linear;
		background: none;
		padding: 0;
	}

	.resize-active .nav-box {
		-webkit-transition: none;
		transition: none;
	}

	.nav-active .nav-box {
		max-height: 4000px;
	}

	.nav-box .nav-holder {
		position: static;
		-webkit-transition: all .25s linear;
		transition: all .25s linear;
		-webkit-transform: translateY(-100%);
		-ms-transform: translateY(-100%);
		transform: translateY(-100%);
		background: #fff;
	}

	.nav-active .nav-box .nav-holder {
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}

	.search-section {
		font-size: 15px;
		line-height: 17px;
	}

	.search-section h1 {
		font-size: 24px;
		line-height: 26px;
	}

	.search-section .holder {
		padding: 30px 15px 34px;
	}

	.search-section.alt {
		margin: 0 0 20px;
	}

	.search-section.alt .holder {
		padding: 30px 15px 34px;
	}

	.search-section.alt h1 {
		font-size: 24px;
		line-height: 26px;
	}

	.search-form {
		padding: 12px 10px 12px 10px;
	}

	.search-form .area input {
		font-size: 12px;
		padding: 5px 10px;
	}

	.products {
		padding: 15px 0 1px;
	}

	.products .col {
		width: 100%;
	}

	.products .col h2 {
		margin-bottom: 5px;
	}

	.products .heading {
		text-align: center;
	}

	.products .heading h1 {
		font-size: 24px;
		line-height: 26px;
		float: none;
		text-align: center;
		margin: 0 0 10px;
	}

	.products .heading .link {
		float: none;
		margin: 0;
	}

	.faq-box {
		margin: 0 0 30px;
	}

	.faq-box .holder {
		padding: 37px 15px 34px;
	}

	.faq-box .title {
		font-size: 24px;
		line-height: 26px;
	}

	.faq-box .btn {
		font-size: 16px;
		line-height: 20px;
		padding: 18px 24px 18px;
	}

	.add-box {
		margin: 0 0 30px;
	}

	#footer .holder {
		padding: 22px 0 30px;
	}

	#footer .col {
		width: 100%;
	}

	#footer .btns {
		margin: 0 -15px 15px;
	}

	#footer .btns span {
		width: 100%;
		margin-bottom: 10px;
	}

	#footer .currency {
		margin-bottom: 10px;
	}

	#nav {
		float: none;
		margin: 0;
		border-bottom: 1px solid #000;
	}

	#nav > li {
		margin: 0;
		border-top: 1px solid #000;
		float: none;
	}

	#nav > li:before,
	#nav > li:after {
		display: none !important;
	}

	#nav > li:hover > a,
	#nav > li.active > a {
		color: #f9d41f;
	}

	#nav > li > a {
		padding: 10px 15px;
		display: block;
	}

	.post-section {
		margin: 0;
		font-size: 16px;
		padding: 10px 0 10px;
	}

	.team-section h1 {
		padding: 16px 15px 13px;
		font-size: 20px;
	}

	.team-section .team-list {
		margin: 0 0 10px;
	}

	.team-section .team-list > li {
		width: 100%;
		float: none;
		padding: 0 0 15px;
	}

	.products-list {
		margin: 0 -15px 1px;
	}

	.products-list > li {
		width: 100%;
	}

	.paging {
		margin: 0 0 20px;
	}

	.paging ul li {
		min-width: 26px;
	}

	.filters .opener {
		font-size: 16px;
	}

	.product-block .heading h1 {
		text-align: center;
		font-size: 20px;
		line-height: 23px;
	}

	.product-block {
		margin: 0;
	}

	.product-block .description p {
		margin: 0 0 16px;
	}

	.product-block .description ul {
		margin: 0 0 10px;
	}

	.product-block .description ul li {
		padding: 0 0 0 19px;
	}
}

